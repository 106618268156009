import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

/**
 * The goal of this service is, to create a bridge between the 'product' and the 'purchase' domains
 * 'product' uses the addArticles method, to add articles ('product' -> 'shared')
 * 'purchase' uses cartItems$ to get notified about new items added to the cart ('purchase' -> 'shared')
 * This way, we can avoid dependencies between the 'product' and 'purchase' domains.
 */
@Injectable({
  providedIn: 'root',
})
export class CartProxyService {
  #_addedCartItems$ = new ReplaySubject<CartItem>();
  addedCartItems$ = this.#_addedCartItems$.asObservable();

  #_numberOfAddedCartItems$ = new BehaviorSubject<number>(0);
  numberOfAddedCartItems$ = this.#_numberOfAddedCartItems$.asObservable();

  #_requestError$ = new Subject<string>();
  requestError$ = this.#_requestError$.asObservable();

  addArticles(articleId: string, quantity: number): void {
    this.#_addedCartItems$.next({ articleId, quantity });
  }

  setNumberOfCartItems(count: number): void {
    this.#_numberOfAddedCartItems$.next(count);
  }

  setRequestError(error: string): void {
    this.#_requestError$.next(error);
  }
}

export interface CartItem {
  articleId: string;
  quantity: number;
}
